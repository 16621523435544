import React, { Component } from 'react';
// import { BrowserRouter as Router, Routes, Route, Redirect } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import { createTheme } from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles'

// Components
import Navbar from './components/Navbar';

// Pages
import Home from './pages/Home';
import Team from './pages/Team';
import Partners from './pages/Partners';
import Results from './pages/Results';
import Faq from './pages/Faq';
import Contact from './pages/Contact';

const theme = createTheme({
  palette: {
    primary: {
      main: '#bad8f5',
    },
    secondary: {
      main: '#fac619',
    },
  },
});

class App extends Component {
  render() {
    return (
      window.location.href = 'http://imdolympiad.org'
      // <ThemeProvider theme={theme}>
      //   <div className="App">
      //     <Router>
      //       <Navbar />
      //       <div className="container">
      //         <Routes>
      //           <Route path="/" element={<Home/>} />
      //           <Route path="/team" element={<Team/>} />
      //           <Route path="/partners" element={<Partners/>} />
      //           <Route path="/results" element={<Results/>} />
      //           <Route path="/faq" element={<Faq/>} />
      //           <Route path="/contact" element={<Contact/>} />
      //         </Routes>
      //       </div>
      //     </Router>
      //   </div>
      // </ThemeProvider>
    )
  }
}

export default App;
