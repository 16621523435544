import React, { useRef, useState, useEffect } from 'react'

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// TODO: Fix This
const styles = {
    contentDivContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}

function Team(props) {
    // const { classes } = props;

    return (
        <div>
            <h1>Project Team</h1>

            Richard Yang - Executive Director
            <br />
            Anthony Law - Project Manager
            <br />
            Victor Borza - Head Question Setter
            <br />
            Marco Starger - QA
            <br />
            Sarah Yu - Brand Director
            <br />
            Dr. Xiwei Wu - Senior Content Consultant
            <br />
            Dr. Zhang Xing - Senior Content Consultant
            <br />
        </div>
    );
}

export default withStyles(styles)(Team)