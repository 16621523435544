import React, { useRef, useState, useEffect } from 'react'

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// TODO: Fix This
const styles = {
    contentDivContainer: {
        display: 'flex',
        flexDirection: 'column',
    }
}

function Home(props) {
    // const { classes } = props;

    // console.log("Hello");

    return (
        <div className="homeComponent">
            <h1>About IMDO</h1>

            <h2>Overview</h2>

            The International Medicine and Disease Olympiad (IMDO) is the World Championship Medical and Disease knowledge competition for High School students and is held annually online in August. The first IMDO was held in 2015 in San Diego, CA, and subsequently was moved online to lower the cost to compete for students throughout the world. The IMDO Foundation Board ensures that the competition is held each year and that each country selects their team in a fair and transparent process.

        </div>
    );
}

export default withStyles(styles)(Home)