import React, { useRef, useState, useEffect } from 'react'

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Media
import chinaFlag from '../media/countryFlags/chinaFlag.svg';
import usaFlag from '../media/countryFlags/usaFlag.png';

// TODO: Fix This
const styles = {
    contentDivContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    flagSeries: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justify: 'center',
        alignItems: "center",
        justifyContent: 'center',
    },
    flag: {
        maxWidth: '200px',
        maxHeight: '200px',
        width: '20%',
        height: '20%',
        margin: "20px",

    }
}

function Partners(props) {
    const { classes } = props;

    return (
        <div>
            <h1>IMDO Partners</h1>

            Starting with IMDO 2022, the IMDO Foundation has agreed to partner with different organizations (1 per country/territory) to select representative teams for their respective country/territory. Currently, was are partners with organizations in the USA and China. For countries without representative organizations, established organizations can reach out and apply to become representatives for their respective countries. Individual competitors of countries without representative organizations can still qualify to compete for the IMDO by competing in the USMDO (hosted by our USA partner organization) and selecting their country during the competition.

            <br /><br />
            USA - Organization: Creative Odyssey, Contact: Jack Gao
            <br />
            China - Organization: Huan Ying, Contact: Ke Zhang
            {/* <div className={classes.flagSeries}>
                <img className={classes.flag} src={usaFlag} />
                <img className={classes.flag} src={chinaFlag} />
            </div> */}
        </div>
    );
}

export default withStyles(styles)(Partners)