import React, { useRef, useState, useEffect } from 'react'

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// TODO: Fix This
const styles = {
    contentDivContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}

function Contact(props) {
    // const { classes } = props;

    return (
        <div>
            <h1>Contact Us</h1>

            Please direct all your inquiries to BiologyOlympiads@gmail.com
        </div>
    );
}

export default withStyles(styles)(Contact)