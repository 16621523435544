import React, { useRef, useState, useEffect } from 'react'

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// TODO: Fix This
const styles = {
    contentDivContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}

function Faq(props) {
    // const { classes } = props;

    return (
        <div>
            <h1>Faqs</h1>

            Q1: How can I qualify for the IMDO?
            <br />
            Answer: The IMDO is partnered with different organizations in different countries to select teams to represent their respective countries at the IMDO. To find your country's partner organization, please visit our "Partners" page. If after March 1st your country still does not have a partner organization, please just take the USMDO and specify on the USMDO exam 
        </div>
    );
}

export default withStyles(styles)(Faq)