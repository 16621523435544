import React, { useRef, useState, useEffect } from 'react'

// MUI stuff
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// Media
import imdo2021Results1 from '../media/IMDO2021Results1.webp';
import imdo2021Results2 from '../media/IMDO2021Results2.webp';
import imdo2021Results3 from '../media/IMDO2021Results3.webp';

// TODO: Fix This
const styles = {
    contentDivContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    pictureSeries: {
        display: 'flex',
        flexDirection: 'column',
        justify: 'center',
        alignItems: "center",
        justifyContent: 'center',
    },
}

function Results(props) {
    const { classes } = props;

    return (
        <div>
            <h1>Results</h1>

            <div className={classes.pictureSeries}>
                <h2>IMDO 2021</h2>
                <img src={imdo2021Results1} />
                <img src={imdo2021Results2} />
                <img src={imdo2021Results3} />
            </div>


        </div>
    );
}

export default withStyles(styles)(Results)